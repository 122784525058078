import MsUrl from '../../../types/MsUrl'
const Php8: MsUrl = {
  idms: 'https://idms.prod.gappifyinc.com',
  txn_manager: 'https://ms-transaction-manager.prod.gappify.com',
  review_center: 'https://ms-review-center.prod.gappify.com',
  qje_manager: 'https://ms-qje.prod.gappify.com',
  taskmanager: 'https://ms-accrual-manager.prod.gappify.com',
  history_logs: 'https://ms-history.prod.gappify.com',
  analytics: 'https://ms-advanced-analytics.prod.gappify.com',
  token_duration: 1800,
  token_exp_allowance: 1,
  schedule_manager: 'https://ts-v2.prod.gappifyinc.com',
  schedule_manager_env: 'prod',
  search: 'https://search.prod.gappifyinc.com',
  period_manager: 'https://period-manager.prod.gappifyinc.com',
  settings: 'https://ms-settings.prod.gappify.com',
  gappify_api: 'https://internal-api.prod.gappifyinc.com/'
} as const
export default Php8