import * as FE_URL from './../helpers/constants/fe-urls'
import Dev from '../helpers/constants/ms-urls/Dev'
import Qa from '../helpers/constants/ms-urls/Qa'
import Staging from '../helpers/constants/ms-urls/Staging'
import Prod from '../helpers/constants/ms-urls/Prod'
import Local from '../helpers/constants/ms-urls/Local'
import Php8_DEV from '../helpers/constants/ms-urls/Php8-dev'
import Php8_QA from '../helpers/constants/ms-urls/Php8-qa'
import Php8_STAGING from '../helpers/constants/ms-urls/Php8-staging'
import Php8_UAT from '../helpers/constants/ms-urls/Php8-uat'
import Php8_PROD from '../helpers/constants/ms-urls/Php8-prod'

import type MsUrl from '../types/MsUrl'

export default function getMsUrl(hostname: string): MsUrl {
  if (hostname === FE_URL.DEV) {
    return Php8_DEV //updated PHP8.3 urls
  }

  if (hostname === FE_URL.QA) {
    return Qa
  }

  if (hostname === FE_URL.QA_MSB) {
    return Php8_QA //updated PHP8.3 urls
  }

  if (hostname === FE_URL.STAGING) {
    return Staging
  }

  if (hostname === FE_URL.STAGING_MSB) {
    return Php8_STAGING //updated PHP8.3 urls
  }

  if (hostname === FE_URL.UAT) {
    return Php8_UAT //updated PHP8.3 urls
  }

  if (hostname === FE_URL.UAT_MSB) {
    return Php8_UAT //updated PHP8.3 urls
  }

  if (hostname === FE_URL.PRE_PROD) {
    return Prod
  }

  if (hostname === FE_URL.PROD_NEXT) {
    return Prod
  }

  if (hostname === FE_URL.PROD) {
    return Php8_PROD //updated PHP8.3 urls
  }

  if (hostname === FE_URL.LOCAL) {
    return Local
  }

  if (hostname === FE_URL.DOCKER_BE) {
    return Dev
  }

  // Updates for the PHP 8.3 Upgrade
  if (hostname === FE_URL.DEV_PHP8) {
    return Php8_DEV
  }

  if (hostname === FE_URL.QA_PHP8) {
    return Php8_QA
  }

  if (hostname === FE_URL.STAGING_PHP8) {
    return Php8_STAGING
  }

  return Local
}
