import MsUrl from '../../../types/MsUrl'

const Php8: MsUrl = {
  idms: 'https://idms.uat.gappifyinc.com',
  txn_manager: 'https://ms-transaction-manager.uat.gappify.com',
  review_center: 'https://ms-review-center.uat.gappify.com/',
  qje_manager: 'https://ms-qje.uat.gappify.com',
  taskmanager: 'https://ms-accrual-manager.uat.gappify.com',
  history_logs: 'https://ms-history.uat.gappify.com',
  analytics: 'https://ms-advanced-analytics.uat.gappify.com',
  token_duration: 1800,
  token_exp_allowance: 1,
  schedule_manager: 'https://ts-v2.uat.gappifyinc.com',
  schedule_manager_env: 'uat',
  search: 'https://search.uat.gappifyinc.com',
  period_manager: 'https://period-manager.uat.gappifyinc.com',
  settings: 'https://ms-settings.uat.gappify.com',
  gappify_api: 'https://internal-api.uat.gappifyinc.com'
} as const

export default Php8

